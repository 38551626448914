/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import ProjectsDetailPage from '../components/ProjectsDetailPage';
import Seo from '../components/seo';

import '../styles/global.scss';

const ProjectPage = ({ data, pageContext }) => {
  const { localizations, locale } = data.strapiProject;
  const projectMetadata = {
    defaultSeo: {
      metaTitle: data.strapiProject.title,
      metaDescription: data.strapiProject.title,
      shareImage: data.strapiProject.image.url,
    },
  };
  const global = data.strapiGlobal;
  return (
    <>
      <Seo seo={projectMetadata} global={global} />
      <Layout
        global={global}
        pageContext={{ ...pageContext, localizations, locale }}
      >
        <ProjectsDetailPage
          data={data.strapiProject}
          pageContext={pageContext}
          locale={locale}
          global={global}
        />
      </Layout>
    </>
  );
};

export default ProjectPage;

export const query = graphql`
  query projectsPage($id: String!, $locale: String!) {
    strapiGlobal(locale: { eq: $locale }) {
      navbar {
        companyLogo {
          url
        }
        companyLogoBlack {
          url
        }
        mainLinks {
          title
          url
        }
      }
      siteName
      siteDescription
      footer {
        footerContentTitle {
          title
        }
        company {
          title
          url
          id
        }
        service {
          title
          url
          id
        }
        contact {
          addressTitle
          address
          phoneTitle
          phoneNumber
          emailTitle
          email
        }
        legal {
          title
          url
          id
        }
        copyright {
          titleOne
          titleTwo
        }
        footerBackgroundImage {
          url
        }
        footerBackgroundMobileImage {
          url
        }
        companyLogo {
          url
        }
      }
      projectSliderTitle
    }
    strapiProject(id: { eq: $id }) {
      image {
        url
      }
      locale
      slug
      title
      locationTitle
      location
      localizations {
        data {
          attributes {
            locale
          }
          id
        }
      }
      numberOfObjects
      numberOfObjectsTitle
      ourRole {
        data {
          ourRole
        }
      }
      childStrapiProjectProjectdescriptionTextnode {
        projectDescription
      }
      ourRoleTitle
      surfaceArea
      surfaceAreaTitle
      buildingTypeTitle
      buildingType
      sliderImages {
        url
      }
    }
  }
`;
